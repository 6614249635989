@import "tailwind";
@import "~swiper/swiper-bundle";

:root {
    --primaria: #444;
    --secundaria: #0bc;
    --swiper-theme-color: var(--secundaria);
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    a,
    a:visited,
    a:active,
    a:hover,
    a:any-link {
        text-decoration: none;
        border: none;
        @media only screen and (pointer) {
            cursor: pointer;
        }
    }
    * {
        box-sizing: border-box;
    }

    .comentarios {
        a,
        a:visited {
            color: var(--secundaria) !important;
        }
    }
}

html,
body {
    margin: 0;
    padding: 0;
}

app-menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--primaria);
}

app-banners-materias {
    width: 100%;
    min-height: 400px;
}

app-publicidade {
    padding: 0;
    width: 100%;
}

app-lista-materias,
app-blog-lista-posts {
    width: 100%;
}

header {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    background-color: var(--primaria);
    transition: height 0.3s;
    height: 60px;
    margin-bottom: 0;
    box-shadow: 0px 2px 5px -2px var(--primaria);
}

main {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 300px;
    background-color: white;
}

footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--primaria);
}

section {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
}

article {
    a,
    a:visited,
    a:active,
    a:hover,
    a:any-link {
        color: var(--secundaria) !important;
        font-weight: 400;
    }
    cite {
        display: block;
        margin: 1rem 0 1rem 1.5cm;
    }
    ul,
    ol {
        margin-left: 0.5cm;
    }
    li {
        margin-bottom: 10px;
        list-style: inside;
    }
    p,
    li {
        @apply text-justify;
    }
}

h1 {
    @apply text-2xl sm:text-3xl my-4;
    font-weight: 700;
}

article h1 {
    @apply mb-8;
}

h2 {
    @apply text-xl;
    font-weight: 700;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    text-align: center;
    text-transform: uppercase;
}

article h2 {
    text-transform: none;
    font-weight: 600;
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;
    text-align: left;
}

h3 {
    @apply text-lg;
    margin-bottom: 30px;
    font-weight: 400;
    text-align: center;
}

h4 {
    color: var(--primaria);
    @apply text-sm;
    text-align: right;
    margin: 0;
    font-weight: 400;
}

p {
    margin: 1rem 0;
}

hr {
    width: 100%;
    max-width: 1000px;
    margin: auto;
    z-index: 1000;
    height: 1px;
    background: rgb(154, 238, 255);
    background: linear-gradient(
        90deg,
        transparent 20%,
        rgb(154, 238, 255) 50%,
        transparent 80%
    );
    border: unset;
}

.carregando {
    -webkit-animation-name: spin;
    -webkit-animation-duration: 1500ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: 1500ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: 1500ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    animation-name: spin;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
@-ms-keyframes spin {
    from {
        -ms-transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
    }
}
@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.headerMaior {
    height: 200px;
    margin-bottom: -90px;
    box-shadow: unset;
}

.divisorPrincipal {
    margin-top: -8px;
    @media only screen and(max-width: 500px) {
        margin-top: -10px;
    }
}

.router {
    width: 100%;
    max-width: 1000px;
    padding: 0;
    > :last-child {
        width: 100%;
    }
}

#inicio {
    height: 100%;
    width: 100%;
    overflow: auto;
    background-color: white;
}

.headerContainer {
    display: inherit;
    width: 100%;
}

.limitador {
    max-width: 1000px;
}

.divisorSecao {
    margin: 50px 0;
}

.logoWrapper {
    height: 100px;
    background-color: var(--primaria);
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
    width: 100%;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    width: 430px;
    @media only screen and (max-width: 550px) {
        width: 215px;
    }
}

.miniLogo {
    width: 100%;
    height: 100%;
    max-height: 60px;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primaria);
    z-index: 1000;
    a {
        height: 60px;
        width: 60px;
        background-image: url("/assets/logo_menor.png");
        background-position: center;
        background-size: auto 50px;
        background-repeat: no-repeat;
    }
}

.secoes {
    display: flex;
    justify-content: space-between;
    background-color: var(--primaria);
    color: white;
    height: 100px;
    padding: 20px;
}

.banner-imagem {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @media (min-width: 500px) {
        @apply p-4 pb-8;
    }
}

.mancheteContainer {
    width: 100%;
    max-width: 500px;
    padding: 1rem 1rem 2rem;
    background-color: rgba(0, 0, 0, 0.6);
}

.secaoManchete {
    display: inline-block;
    margin-top: 1rem;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
}

.manchete {
    font-size: 1.6rem;
    line-height: 2rem;
    color: white;
}

.subtitulo {
    @apply text-gray-200;
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-weight: 300;
    margin-top: 1rem;
}

#redessociais {
    .iconesRedes {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 30px;
        .iconesWrapper {
            display: flex;
            width: 100%;
            justify-content: space-around;
            width: 340px;
            margin-bottom: 30px;
            a {
                width: 100px;
                height: 100px;
                background-size: 100px 100px;
                background-position: center;
            }
        }
    }
}

#expediente {
    .staffContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }
}

#faleconosco {
    margin-bottom: 50px;
}

.botaoFlutuante {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: var(--secundaria);
    position: fixed;
    bottom: 20px;
    right: 20px;
    box-shadow: black 2px 2px 5px -4px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.botaoFlutuante:active {
    box-shadow: none;
    transform: translate(1px, 1px);
}

.setaCima {
    width: 100%;
    height: 100%;
    background-image: url("/assets/icones/up.svg");
    background-repeat: no-repeat;
    background-position: center;
    filter: invert(1);
}

.mais {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.maisLogo {
    width: 60px;
    height: 60px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.containerNoticias {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 100%;
    overflow: hidden;
}

.containerSecao {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    max-width: 1000px;
    > :last-child {
        width: 100%;
    }
}

#rodape {
    color: white;
    text-align: center;
    font-weight: 500;
    .wrapperTermos {
        display: flex;
        width: 100%;
        max-width: 600px;
        justify-content: space-around;
        padding: 40px 0;
        div {
            margin: 20px;
        }
    }
}

.maisNoticias {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.5rem;
    padding: 10px 0;
    .material-icons {
        font-feature-settings: "liga";
        font-size: 2rem;
    }
    background-color: white;
}

.descricaoImagemMateria {
    padding: 0 20px;
    width: 1000px;
    max-width: 100%;
    font-size: 0.8rem;
}

.imagemInternaMateria {
    padding: 6px 20px 20px;
    max-width: 100%;
    min-width: 320px;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    img {
        max-width: 100%;
        max-height: 300px;
        border-radius: 5px;
    }
}

@media only screen and (max-width: 500px) {
    .imagemInternaMateria {
        width: 100%;
    }
}

.esquerda {
    float: left;
}

.direita {
    float: right;
}

.referencia {
    font-size: 0.9rem;
    word-break: break-word !important;
    max-width: 100%;
    p {
        word-break: break-word !important;
    }
}

.containerChamada {
    width: 100%;
    padding: 20px;
}

.imagemMateria {
    width: 100%;
    max-width: 1000px;
    margin-bottom: 5px;
    height: calc(min(1000px, 100vw) / 1.78);
    background: no-repeat center/cover;
}

center {
    width: calc(100% + 40px);
    max-width: 1000px;
    margin-left: -20px;
}

@media only screen and (pointer) {
    .maisNoticias:hover {
        background-color: #0bc1;
    }
}

.voltarContainer {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    max-width: 1000px;
    position: absolute;
    top: 0;
    left: max((100% - 1000px) / 2, 0px);
}

.botaoVoltar {
    width: 60px;
    height: 60px;
    z-index: 1100;
    background-image: url("/assets/icones/back.svg");
    background-repeat: no-repeat;
    background-position: center;
    filter: invert(1);
}

.botao {
    outline: none;
    display: inline-block;
    padding: 10px 20px;
    margin: 10px;
    border-radius: 10px;
    font-weight: 500;
    text-transform: uppercase;
    background-color: var(--secundaria);
    color: white;
    box-shadow: black 2px 2px 6px -3px;
    border: unset;
    appearance: unset;
    @media only screen and (pointer) {
        cursor: pointer;
    }
}

.botao:hover {
    filter: saturate(1.5);
    -webkit-filter: saturate(1.5);
}

.botao:active {
    transform: translate(2px, 2px);
    box-shadow: unset;
}
.botao,
.botao:active,
.botao:visited,
.botao:hover,
.botao:focus {
    font-weight: 500;
}

.botao:disabled {
    background-color: lightgray;
    color: white !important;
    box-shadow: none;
    cursor: default;
}

.botao.secundario {
    background-color: var(--primaria);
    color: white;
}

.primaria {
    color: var(--secundaria);
}

.bg-secundaria {
    background-color: var(--secundaria);
}

.bg-primaria {
    background-color: var(--primaria);
}

.assinatura {
    padding: 20px;
    font-style: italic;
    width: 100%;
    img {
        max-width: min(100%, 300px);
        max-height: 150px;
    }
}

.clicavel {
    @media only screen and (pointer) {
        cursor: pointer;
    }
}

.privacidadeWrapper {
    width: 100%;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 999999;
    bottom: 20px;
    transition: bottom 1s;
    background-color: var(--primaria);
    padding: 10px;
    box-shadow: black 0px 2px 8px -4px;
    .privacidadeAlerta {
        width: 100%;
        max-width: 1000px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: white;
        font-weight: 400;
        flex-grow: 2;
        .privacidadeTexto {
            margin: 10px;
            flex-grow: 1;
        }
    }
    @media only screen and (max-width: 500px) {
        .privacidadeAlerta {
            flex-wrap: wrap;
            justify-content: center;
            .privacidadeTexto {
                text-align: center;
            }
        }
    }
}

.print-hidden {
    @media print {
        display: none;
    }
}
